import React from "react"
import Log from "./../../../templates/log.js"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Caption, FirstP } from "./../../../components/helpers.js"

export default ({ data, pageContext }) => {

    const content = <>
        
        <FirstP>Now that all the physical components are available it is time to finish the housing and assemble the full clock. First I will talk about the housing design, and the process of getting it printed. After that I will show the final product when it is all put together.</FirstP>
        
        <p>As you could see from the concept drawing in my introduction I wanted to go for a simple and square design of the clock and the housing. 
            Even though I used the most dense LED strip with 144 LEDs per meter, the clock was still going to be over 35cm in length. The good news is that my 3D printer 
            is a Creality CR-10S, which has a bed size of 300x300mm. You might think: hey, but that is not enough... But by printing it diagonally on the bed it just fits!
        </p>
        
        <Img fadeIn={false} fluid={data.img1.childImageSharp.fluid} alt="3D printing the housing" />
        <Caption>Printing the housing on my Creality CR-10S</Caption>        

        <p>
            This is the first project where I have used my own printer. It turns out that the main benefit of 3D printing at home is not necessarily rapid prototyping, but rather
            rapid iteration. I designed everything to be press and snap fit into the housing, such that I could always disassemble the clock in the future. However, to make all the fits
            right I had to go through three iterations. If I would have had to order these three iterations from a service like Shapeways, I would have lost weeks of time, and almost the
            total price that I paid for my printer.
        </p>

        <Img fadeIn={false} fluid={data.img2.childImageSharp.fluid} alt="Three housings" />
        <Caption>After more than 30 hours of total print time: third time's a charm!</Caption>    

        <h3>Final Assembly</h3>

        <p>With the housing finished, it was time to put everything together. I put the ESP8266 at the back side of the frame, to have the core of the clock with the frame,
            LED strips and electronics into one unit. Next to that I printed two guides to fit the core frame into the housing, as well as a small part to fit the USB port to the 
            back of the housing.
        </p>

        <Img fadeIn={false} fluid={data.img3.childImageSharp.fluid} alt="Parts" />
        <Caption>All components ready for assembly</Caption>    

        <Img fadeIn={false} fluid={data.img4.childImageSharp.fluid} alt="Parts" />
        <Caption>Fitting the USB port into the back of the housing</Caption>        

        <p>After putting everything together I am quite happy with the result. You can see the final product in the pictures below. So far I mainly focussed on the hardware and did not really work on the software yet.
            Therefore in the next update I will go into more detail on the software and discuss which data I want to show on the clock, and how to visualize it. 
        </p>

        <Img fadeIn={false} style={{ marginBottom: '1em' }} fluid={data.img6.childImageSharp.fluid} alt="Clock" />

        <Img fadeIn={false} fluid={data.img5.childImageSharp.fluid} alt="Detail of the clock" />
        <Caption>The finished clock after assembly</Caption>    

    </>;

    return (<Log pageContext={pageContext}>{content}</Log>);
}

export const query = graphql`
{
    img1: file(relativePath: { eq: "print.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img2: file(relativePath: { eq: "housings.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img3: file(relativePath: { eq: "parts.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img4: file(relativePath: { eq: "usbport.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img5: file(relativePath: { eq: "clockpart.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img6: file(relativePath: { eq: "clock.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`